<template>
  <div class="login-container">
    <!-- <p class="buttxt">Управление голосованиями</p> -->
    <el-tabs v-model="activeName" class="buttxt">
      <el-tab-pane name="elect">
        <span slot="label" class="seltxt"> Управление голосованиями</span>
          <el-select v-model="el_id" @change="set_elect0" autocomplete style="width:98%;margin:1%" placeholder="Выбор голосования" no-match-text="Ничего нет">
            <el-option
              v-for="item in elects"
              :key="item.id"
              :label="item.name "
              :value="item.id"
            >
              <span class="seltxt">{{item.name}} </span>
            </el-option>
          </el-select>
        <div class="login-form" v-if="el_id">
          <el-checkbox v-model="secret" style="margin: 1%;">Тайное голосование</el-checkbox>
          <el-checkbox v-model="(elects[el_index] || {}).closed" style="margin-left: 69%;">Голосование закончено, итоги подведены</el-checkbox>
          <el-input placeholder="Наименование голосования" v-model="name" style="width:98%;margin: 1%;"></el-input>
          <el-date-picker v-model="beendt" type="daterange" range-separator=" : " style="width:98%;margin: 1%;" :default-value="beendt[0]"
            start-placeholder="Начало" end-placeholder="Окончание" format="dd.MM.yyyy" value-format="yyyy/MM/dd">
          </el-date-picker>
          <el-table border ref="tabQ" :data='electElementsSort' highlight-current-row style="width:98%;margin:1%" @row-click="editQuest" @expand-change="setCurQ">
            <!-- <el-table border ref="tabQ" :data='elect.pages[0].elements' highlight-current-row style="width:98%;margin:1%" @row-click="editQuest" @expand-change="setCurQ"> -->
            <el-table-column label="" type="expand" min-width="6%">
              <template slot-scope="props">
                <el-table :data='props.row.choices' style="width:80%;margin-left:10%" border highlight-current-row @row-click="editAnswer">
                  <el-table-column label="Текст" prop="text.ru"/>
                  <el-table-column label="Значение" prop="value"/>
                </el-table>
                <el-button plain class="butt" type="success" style="width:90%;" icon="el-icon-plus" @click="genAnswer(props.row)">Новый вариант ответа</el-button>
              </template>
            </el-table-column>
            <el-table-column label="Название" prop="name" min-width="34%">
              <span slot="header" class="seltxt">Название</span>
              <template slot-scope="props" >
                <span class="seltxt">{{props.row.name}}</span>
              </template>
            </el-table-column>
            <el-table-column label="Развернутый вопрос" prop="title.ru" min-width="60%">
              <span slot="header" class="seltxt">Развернутый вопрос</span>
              <template slot-scope="props" >
                <span class="tabtxt">{{props.row.title.ru}}</span>
              </template>
            </el-table-column>
        <!-- <el-table-column min-width="10%"><el-button plain @click="set_date"><i class="el-icon-edit" style="color:red;margin: '1px'"/></el-button></el-table-column> -->
          </el-table>
          <el-button plain class="butt" type="success" style="width:90%;" icon="el-icon-plus" @click="genQuest">Новый вопрос</el-button>
          <el-row>
            <el-button class="butt" type="info" @click="diaUploadVis=true" style="width:19%;margin-bottom:1px;">Загрузить вопросы из файла</el-button>
            <el-button class="butt" type="info" @click="saveFile" style="width:19%;margin-left:2%;margin-bottom:1px;">Сохранить вопросы в файл</el-button>
          </el-row>
          <el-button v-if="el_id" plain class="butt" type="primary" @click="save">{{mode=='update' ? 'Сохранить в базе':'Создать'}}</el-button>
          <el-button v-if="mode=='update'" plain class="butt" type="danger" @click="del_elect">Удалить из базы</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane name="text">
        <span slot="label" class="seltxt">Управление текстами</span>
        <el-cascader  style="width:98%;margin: 1%;"
            :options="optionsText"
            @change="selText">
        </el-cascader>
        <vue-editor v-model="contentText" />
        <el-checkbox v-model="viewHtmlText" class="butt">Показать HTML</el-checkbox>
        <el-button type="primary" plain class="butt" @click="editText" v-if="nameText">Сохранить</el-button>
        <el-input type="textarea" autosize v-model="contentText" v-if="viewHtmlText"/>
      </el-tab-pane>
    </el-tabs>

    <el-dialog title="Коррекция" :visible.sync="diaVis" width="86%" center>
      <el-form :model="form">
        <el-form-item label="Номер пункта(подпункта)" label-width="15%" v-if="diaMode == 'quest'">
          <el-input-number style="width:10%;margin-right:10px" v-model="curNum[0]" @change="setNumP" :min="0" :max="100" />
          <el-input-number style="width:10%;margin-right:10px" v-model="curNum[1]" @change="setNumP" :min="0" :max="100" />
          <el-input-number style="width:10%;" v-model="curNum[2]" @change="setNumP" :min="0" :max="100" />
        </el-form-item>
        <el-form-item :label="diaMode == 'quest' ? 'Название вопроса (в результатах)' : 'Текст ответа'" label-width="15%">
          <el-input v-model="form.name" autocomplete="off" >
            <!-- <template slot="prepend"></template> -->
          </el-input>
        </el-form-item>
        <el-button type="primary" plain size="mini" @click="cloneName" icon="el-icon-caret-bottom" style="margin-left:15%; margin-bottom:10px">Скопировать в нижнее поле</el-button>
        <el-form-item :label="diaMode == 'quest' ? 'Текст вопроса' : 'Значение ответа'" label-width="15%">
          <el-input v-model="form.value" type="textarea" class="intxt" rows="6">
          </el-input>
        </el-form-item>
        <el-checkbox v-model="form.row.isRequired" border size="medium">Ответ обязателен</el-checkbox>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="diaVis = false">Отмена</el-button>
        <el-button type="primary" @click="diaSave">Сохранить</el-button>
        <el-button type="danger" v-if="form.add == false" @click="diaDelete">Удалить</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Загрузка вопросов голосования из файла" :visible.sync="diaUploadVis" width="40%" center>
      <input ref="upload" type="file" name="electfile" accept=".json" style="width:90%"/>
          <!-- input( ref="upload" type="file" name="inptfile" enctype="multipart/form-data") -->

      <span slot="footer" class="dialog-footer">
        <el-button @click="diaUploadVis = false">Отмена</el-button>
        <el-button type="primary" @click="diaUpload">Загрузить</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { hostus, getNum, normNum, setNum, maxNum, strPrepForJson } from "../utils/func.js";
import { VueEditor } from "vue2-editor";
import { saveAs } from "file-saver";

//const hostus = (process.env.NODE_ENV == "development" ? 'http://localhost:3000' : '')
const def_choices = [
  {text: { ru: 'За'}, value: 'За'}, 
  {text: { ru: 'Против'}, value: 'Против'}, 
  {text: { ru: 'Воздержался'}, value: 'Воздержался'}]

let KLIZ = 0

export default {
  components: { VueEditor },
  data() {
    return {
      activeName: 'elect',
      nameText: '',
      optionsText: [
        {value: "proto", label: "Преамбулы голосований", children: [{}]},
        {value: "inforeg", label: "Внутренняя информация", children: [{value:"1",label:"Информация пользователю"}]},
        {value: "infopubl", label: "Общая информация", children: [
          {value:"1",label:"Начальная страница"},
          {value:"2",label:"О программе"},
        ]},
      ],
      contentText: '',
      viewHtmlText: false,
      diaVis: false,
      diaUploadVis: false,
      diaMode: '',
      form: {name: '', value: '', add: false, row: {}},
      curQuest: {},
      curNum: [],
      //name: '',
      mode: '',
      el_id: this.$store.getters['elect_id'],
      el_index: 0,
      beendt: '',
      elects: [],
      elect: {
        locale: "ru",
        title: {
          ru: "",
        },
        completedHtml: {
          ru: "<h5>Спасибо за участие</h5>",
        },
        pages: [
          {
            name: "page1",
            elements: [
            ],
          },
        ],
      }
    }
  },
  computed: {
    name: {
      get: function () { return this.elect.title.ru; },
      set: function (newVal) { this.elect.title.ru = newVal }
    },
    secret: {
      get: function () { return (this.elects[this.el_index] || {}).secret == 1 ? true : false },
      set: function (newVal) { this.elects[this.el_index].secret = (newVal == false ? 0 : 1) }
    },
    electElementsSort () {
      let elms = this.elect.pages[0].elements
      let R = elms.sort((a, b) => {
        let an = normNum(a.name) //getNum(a.name).map((el) => {return ('000'+el).slice(-3)}).join('.')
        let bn = normNum(b.name)
        let x = (an > bn ? 1 : -1);
        return x
      }) 
      return R
    }
  },
  created() {
  },
  mounted: async function () { console.log('process.env=', process.env)
    this.el_id=this.$store.getters['elect_id']
    await this.selElects()

    if (this.$store.getters['elect_id'] ) { 
      this.set_elect0 ()
    }
  },
  beforeRouteLeave(to, from, next) {
    if (KLIZ > 0) {
    this.$confirm('Вы хотите уйти?', 'У вас есть изменения, несохранённые в базе!', {confirmButtonText: 'Да', cancelButtonText: 'Отмена', type: 'warning'})
      .then(() => next())
      .catch(() => next(false))
    }
    else next()
  },
  methods: {
    setNumP () {
      //if ( this.curNum[0]==0 ) this.curNum.splice(1, 2)
      let str = setNum(this.form.name, this.curNum)
      this.form.name = str
      this,this.form.value = setNum(this.form.value, this.curNum) 
    },
    cloneName () { console.log(this.form.name)
      //if (this.form.add)
      let txt = ((this.form.value).split(' '))
      txt.splice(0, 1) 
      this.form.value = this.form.name + txt
    },
    async selText (array) { // выбор в меню упр. текстами (возвр. название файла)
      let name = array[0] + '-' + array[array.length - 1]
      let res = await axios.get(`${hostus}/mysqlus/proto`, {params: {file: name }})
      let proto = res.data
      this.nameText = name
      if (proto.errmsg) this.contentText = " Не найден файл"  
      else this.contentText = proto; 
      //console.log('this.nameText=',this.nameText)
    },
    async editText () { // 
      let res = await axios.post(`${hostus}/mysqlus/proto`, {file: this.nameText, content: this.contentText })
      if (res.data.errmsg) { this.$alert(res.data.errmsg, 'Ошибка', {})}
      else this.$alert(this.nameText, "Успешно", {type: 'success'})
      //console.log('this.nameText=',this.nameText)
    },
    save () { //console.log(this.beendt)
      let elect = {name: this.elect.title.ru, beendt: this.beendt, elect: this.elect,
        secret: this.elects[this.el_index].secret || false, closed: this.elects[this.el_index].closed || false}
      if (this.mode == 'update') elect.id = this.el_id
      let title = `Сохранить в базе ${elect.name} ?`
      console.log('elect=', elect)
      this.$confirm(title, 'Подтвердите сохранение', {confirmButtonText: 'Да', cancelButtonText: 'Отмена', type: 'warning'})
        .then(() => axios.post(`${hostus}/mysqlus/ins_elect`, elect).then((res) => {
          // console.log('res.data=',res.data)
          if (res.data.okmsg) {
            KLIZ = 0
            let msg = (res.data.okmsg > 0 ? `Добавлено. Индекс ${res.data.okmsg}` : 'Обновлено.')
            this.$alert(msg, "Успешно", {type: 'success'}); this.el_id = res.data.okmsg
            if (res.data.okmsg > 0) this.selElects()
          }
          else {
            let msg = ''
            if (res.data.error.code == "ER_DUP_ENTRY") msg = "Ошибка. Такое название уже используется."
            else msg = "Ошибка " + res.data.error.message
            this.$alert(res.data.errmsg, msg, {type: 'error', confirmButtonText: 'Закрыть', showClose: false})
          }
        }).catch((err) => { //console.log('err=',err)
          this.$alert(err, "Ошибка ", {type: 'error', confirmButtonText: 'Закрыть', showClose: false}) }) 
        ).catch(() => { })
    },
    del_elect () {
      if (this.mode == 'update') {
        let title = `Удалить из базы ${this.elect.title.ru} ? Будут удалены и результаты по этому голосованию, если они есть!`
        this.$confirm(title, 'Подтвердите удаление !', {confirmButtonText: 'Да', cancelButtonText: 'Отмена', type: 'error'})
          .then(() => axios.delete(`${hostus}/mysqlus/del_elect`, {data: {id: this.el_id}})
            .then((res) => { //console.log('res=',res)
              if (res.data.okmsg) {
                KLIZ = 0
                this.$alert('Успешное удаление', {type: 'success', confirmButtonText: 'Ok'})
                this.el_id = -1
                this.selElects()
              }
              if (res.data.errmsg) this.$alert("Удаление не выполнено!", res.data.errmsg, {type: 'error', confirmButtonText: 'ОЙ'});
            }).catch((err) => {this.$alert(err, "Ошибка ", {type: 'error', confirmButtonText: 'Закрыть', showClose: false}) })
          ).catch(() => { })
      }
    },
    async selElects () {
      return axios.post(`${hostus}/mysqlus/sel_elect`, {id: -1, usl: '>'})
        .then(response => { 
          const { data } = response; console.log('data=',data)
          if (data.errmsg) { this.$alert(data.errmsg, 'Ошибка', {})}
          else {
            this.elects = data.slice()
            console.log('selElects: this.elects=',this.elects)
            this.optionsText[0].children = this.elects.map((item) => {
              return {value: item.id, label: item.name} 
            })
            this.elects.unshift({id: -1, name: 'Создать новое...'})
            //if (this.elects.length > 0) this.set_elect(0)
          }
      })
    },
    set_elect0 () {
      let ind = this.elects.findIndex(item => item.id == this.el_id)
      this.el_index = ind
      if (this.el_id > -1) {
        this.mode = 'update'
        let elect = JSON.parse(this.elects[ind].content)
        this.elect = Object.assign({}, elect)
        this.name = this.elects[ind].name
      }
      else {
        this.mode = 'create'
        this.name = 'Новое'
        this.elect.pages[0].elements = []
      }
      let dt = new Date()
      this.beendt = [this.formdt(this.elects[ind].bedt || dt), this.formdt(this.elects[ind].endt || dt.setMonth(dt.getMonth()+1))]
      //console.log('set_elect0: this.beendt=', this.beendt)
    },
    setCurQ(row, exp) {
      //console.log('row=',row,'exp=', exp)
      if (exp.length > 1) {
        exp.forEach(el => {
          if (el.name != row.name) this.$refs.tabQ.toggleRowExpansion(el, false) // закрыть все остальные
        });
      }
      this.curQuest = row
    },
    genQuest() {
      //this.curNum.splice(0)
      if (this.curNum[2]>0) this.curNum[2]++
      else if (this.curNum[1]>0) this.curNum[1]++
      else if (this.curNum[0]>0) this.curNum[0]++
      else this.curNum[0] = maxNum(this.elect.pages[0].elements) + 1
      this.diaMode = 'quest'
      this.form.name = setNum(' ', this.curNum)
      this.form.value = setNum(' ', this.curNum)
      this.form.add = true
      this.diaVis = true
    },
    editQuest(row) {  
      //this.curNum.splice(0)
      this.curNum = (getNum(row.name)).slice()
      console.log('elect.pages[0].elements=', this.elect.pages[0].elements)
      this.diaMode = 'quest'
      this.form.name = row.name
      this.form.value = row.title.ru
      this.form.add = false
      this.form.row = row
      this.diaVis = true
    },
    genAnswer(parentRow) { 
      this.diaMode = 'answer'
      this.form.name = ''
      this.form.value = ''
      this.form.add = true
      this.curQuest = parentRow
      this.diaVis = true
    },
    editAnswer(row) {
      this.diaMode = 'answer'
      this.form.name = row.text.ru
      this.form.value = row.value
      this.form.add = false
      this.form.row = row
      this.diaVis = true
    },
    diaUpload () { // загрузка голосования из файла
      let file = this.$refs.upload.files[0]
      const th = this
      const truType = (file.type === 'application/json' || file.type === 'json')
      if (truType) {
        let reader = new FileReader();
        reader.readAsText(file);
        reader.onload = function() {
          let res = JSON.parse(reader.result)
          //console.log('res=', res)
          th.elect = Object.assign({}, res)
          th.diaUploadVis = false
        }
        reader.onerror = function() { this.$message.error(reader.error); }
      }
      else { this.$message.error('Расширение файла должно быть .json') }
    },
    async saveFile () { //сохранение голосования в файл
      let date = new Date() //.getTime()
      let fname = this.elect.title.ru + '_' + date.toLocaleString("ru")
      let x = await this.$prompt('(имя можно изменить)', 'Сохранить файл...', {inputValue: fname})
      console.log('x=',x)
      if (x.action == 'confirm') {
        fname = x.value
        let elect = JSON.stringify(this.elect)
        const blb = new Blob([elect], { type: 'application/json' })
        saveAs(blb, fname)
      }
    },
    diaSave() {
      KLIZ += 1
      // let reg = /(?<!\\)"/g // рег.выражение для поиска неэекранированных двойных кавычек
      // let name = this.form.name.replace(reg, '\\"')
      // let value = this.form.value.replace(reg, '\\"')
      // name = name.replace(/\t/g, ' ')
      // name = name.replace(/'/g, '`')
      // value = value.replace(/\t/g, ' '); 
      let name = strPrepForJson(this.form.name)
      let value = strPrepForJson(this.form.value)
      if (this.diaMode == 'quest') {
        let quest = {type: "radiogroup", "hideNumber": true, name: name, title: {ru: value,}, isRequired: true, choices: def_choices}
        console.log('quest=',quest)
        if (this.form.add) this.elect.pages[0].elements.push(quest)
        else { 
          this.form.row.name = name //this.form.name;
          this.form.row.title.ru = value //this.form.value
        }
      }
      if (this.diaMode == 'answer') {
        //let answer = {value: this.form.value, text: {ru: this.form.name}}
        let answer = {value: value, text: {ru: name}}
        if (this.form.add) this.curQuest.choices.push(answer)
        else { console.log('this.form.row=',this.form.row,'this.form=',this.form)
          this.form.row.value = value //this.form.value;
          this.form.row.text.ru = name //this.form.name
        }
      }
      this.diaVis = false
      //console.log('this.form.row=',this.form.row)
    },
    diaDelete () {
      this.$confirm('Удаляем этот пункт? (' + this.form.row.name + ')' , 'Подтвердите удаление', {confirmButtonText: 'Да', cancelButtonText: 'Отмена', type: 'error'}) 
        .then( () => {
          let arr, index, row = this.form.row
          if (this.diaMode == 'quest') {
            arr = this.elect.pages[0].elements
            index = arr.findIndex(el => el.name === row.name);
          }
          if (this.diaMode == 'answer') {
            arr = this.curQuest.choices 
            index = arr.findIndex(el => el.value === row.value);
          }
          if (index !== -1) {
            arr.splice(index, 1);
          }
        })
      this.diaVis = false
    },
    formdt(date) { let dt = new Date(date); return `${dt.getFullYear()}/${dt.getMonth()+1}/${dt.getDate()}`},
  },
}
</script>

<style scoped>
.login-container {
    position: relative;
    width: 98%;
    max-width: 100%;
    padding: 5px 0px 10px;
    margin: 0 auto;
  background-color: rgb(240, 236, 208);
  overflow: hidden;
}
.el-collapse-item__content div {background-color: rgb(228, 208, 144); color:brown}
.expanded div {background-color:lightblue}
.login-form {
    position: relative;
    width: 100%;
    max-width: 100%;
    padding: 5px 0px 0px;
    margin: 0 auto;
    background-color: rgb(219, 218, 206);
    overflow: hidden;
}
.butt{
  width:40%;
  margin-left:5%;
  margin-top:10px;
  margin-bottom:10px;
}
.buttxt {
  font-size: 1rem;
  margin-left:20px;
  margin-right:20px;
}
.seltxt {
  font-size: 1.0rem;
  margin-left:1%;
}
.tabtxt {
  font-size: 1.0rem;
  color: navy;
  margin-left:1%;
}
.intxt {
  font-size: 0.9rem;
  color:darkblue;
 
}
</style>
